import { toastSuccess, toastError } from './../../../helpers/toastHelper';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleCreateOrUpdateAdminFunction } from 'src/apis/service/AdminRoleSetting';
import { CreateOrUpdateAdminFunctionTypes } from '../../actions/createOrUpdateAdminFunction';
import {
  createOrUpdateAdminFunctionError,
  createOrUpdateAdminFunctionFetching,
  createOrUpdateAdminFunctionFilter,
  createOrUpdateAdminFunctionSuccess,
} from '../../actions/createOrUpdateAdminFunction/action';

function* createOrUpdateAdminFunctionSaga(action: any): Generator<any> {
  yield put(createOrUpdateAdminFunctionFetching(true));
  const { payload, history, callBack } = action;
  try {
    const data: any = yield handleCreateOrUpdateAdminFunction(payload);
    if (data && data?.status === 200) {
      // yield put(createOrUpdateAdminFunctionSuccess(data));
      toastSuccess('bo_update_admin_function_success');
      callBack && callBack();
    } else {
      // yield put(createOrUpdateAdminFunctionError('error'));
      toastError('bo_you_can_t_update_admin_function_setting');
    }
    // history.push('/dashboard/settings/admin-role-setting');
  } catch (error) {
    yield put(createOrUpdateAdminFunctionError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(createOrUpdateAdminFunctionFilter(action.payload));
}
export function* watchCreateOrUpdateAdminFunction() {
  yield takeEvery(
    CreateOrUpdateAdminFunctionTypes.CREATE_OR_UPDATE_ADMIN_FUNCTION,
    createOrUpdateAdminFunctionSaga
  );
  yield debounce(
    500,
    CreateOrUpdateAdminFunctionTypes.CREATE_OR_UPDATE_ADMIN_FUNCTION_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
