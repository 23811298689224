import { CreateOrUpdateAdminFunctionTypes } from './actionTypes';
import {
  ICreateOrUpdateAdminFunction,
  ICreateOrUpdateAdminFunctionFetching,
  ICreateOrUpdateAdminFunctionSuccess,
  ICreateOrUpdateAdminFunctionError,
  ICreateOrUpdateAdminFunctionFilter,
  ICreateOrUpdateAdminFunctionFiterDebounce,
} from './interfaces';

export const createOrUpdateAdminFunction = (
  payload: Object,
  history?: any,
  callBack?: any
): ICreateOrUpdateAdminFunction => ({
  type: CreateOrUpdateAdminFunctionTypes.CREATE_OR_UPDATE_ADMIN_FUNCTION,
  payload,
  history,
  callBack,
});

export const createOrUpdateAdminFunctionFetching = (
  isFetching: boolean
): ICreateOrUpdateAdminFunctionFetching => ({
  type: CreateOrUpdateAdminFunctionTypes.CREATE_OR_UPDATE_ADMIN_FUNCTION_FETCHING,
  isFetching,
});

export const createOrUpdateAdminFunctionSuccess = (
  data: any
): ICreateOrUpdateAdminFunctionSuccess => ({
  type: CreateOrUpdateAdminFunctionTypes.CREATE_OR_UPDATE_ADMIN_FUNCTION_SUCCESS,
  data,
});

export const createOrUpdateAdminFunctionError = (
  errorMsg: any
): ICreateOrUpdateAdminFunctionError => ({
  type: CreateOrUpdateAdminFunctionTypes.CREATE_OR_UPDATE_ADMIN_FUNCTION_FAILD,
  error: errorMsg,
});

export const createOrUpdateAdminFunctionFilter = (
  payload: any
): ICreateOrUpdateAdminFunctionFilter => ({
  type: CreateOrUpdateAdminFunctionTypes.CREATE_OR_UPDATE_ADMIN_FUNCTION_FILTER,
  payload,
});

export const createOrUpdateAdminFunctionFilterDebounce = (
  payload: any
): ICreateOrUpdateAdminFunctionFiterDebounce => ({
  type: CreateOrUpdateAdminFunctionTypes.CREATE_OR_UPDATE_ADMIN_FUNCTION_FILTER_DEBOUNCE,
  payload,
});
